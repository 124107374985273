<template>
  <section class="app-container">
    <div class="top-filter">
      <div class="mb12">
        <FilterLayout
            @doReset="resetForm"
            @doSearch="doSearch"
            @doCollapseEffect="getChartContainerStyle"
            ref="filterLayoutRef"
            @showMoreForm="showMoreForm"
            :moreBtnVisible="moreBtnVisible"
            :isShowMoreFlag="isShowMoreList"
        >
          <template #customform>
            <a-form
                ref="formRef"
                class="form rms-form"
                layout="vertical"
                :model="formState"
            >
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index<=3">
                  <a-form-item :label="item.code" :name="item.inputDataType" class="rms-form-item">
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          placeholder="Name/GGID"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='4'">
                      <a-radio-group
                          v-model:value="formState[item.inputDataType]"
                          button-style="solid"
                          class="btnRadio"
                      >
                        <a-radio-button
                            :value="item.value"
                            v-for="(item, index) in getOptions(item.inputDataType)"
                            :key="index"
                        >{{ item.label }}</a-radio-button>
                      </a-radio-group>
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                      >
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="['Start date', 'End date']"
                          format="YYYY-MM"
                          valueFormat="YYYY-MM"
                          :allowClear="false"
                          :mode="mode"
                          :open="isOpen"
                          @openChange="(status)=>getOpenChange(status)"
                          @panelChange="(value)=>getPanelChange(value)"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
              <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
                <template v-for="(item,index) in queryList" :key="index">
                  <template v-if="index>3">
                    <a-form-item :label="item.code" :name="item.inputDataType" class="rms-form-item">
                      <template v-if="item.type==='3'">
                        <a-input
                            v-model:value="formState[item.inputDataType]"
                            placeholder="Name/GGID"
                            allowClear
                            autocomplete="off"
                        />
                      </template>
                      <template v-if="item.type==='4'">
                        <a-radio-group
                            v-model:value="formState[item.inputDataType]"
                            button-style="solid"
                            class="btnRadio"
                        >
                          <a-radio-button
                              :value="item.value"
                              v-for="(item, index) in getOptions(item.inputDataType)"
                              :key="index"
                          >{{ item.label }}</a-radio-button>
                        </a-radio-group>
                      </template>
                      <template v-if="item.type==='5'">
                        <SelectWithAll
                            v-model:value="formState[item.inputDataType]"
                            placeholder="请选择"
                            mode="multiple"
                            showArrow
                            :maxTagCount="1"
                            allowClear
                            :options="getOptions(item.inputDataType)"
                            @change="getSelectChange(item.inputDataType)"
                        >
                        </SelectWithAll>
                      </template>
                      <template v-if="item.type==='6'">
                        <a-range-picker
                            v-model:value="formState[item.inputDataType]"
                            :placeholder="['Start date', 'End date']"
                            format="YYYY-MM"
                            valueFormat="YYYY-MM"
                            :allowClear="false"
                            :mode="mode"
                            :open="isOpen"
                            @openChange="(status)=>getOpenChange(status)"
                            @panelChange="(value)=>getPanelChange(value)"
                        />
                      </template>
                    </a-form-item>
                  </template>
                </template>
              </MoreFormList>
            </a-form>
          </template>
          <template #operationBtns>
            <div class="buttons-wrap right">
              <a-button class="ant-btn ant-btn-primary ant-btn-background-ghost margin-r" @click="configFactor">系数配置</a-button>
              <ModalBox
                  :modelValue="showForm"
                  title="系数配置"
                  cancelText="取消"
                  okText="确定"
                  class="custom-modal"
                  :bodyStyle="{ height: '150px' }"
                  :ok="saveCoefficient"
                  :cancel="cancelCoefficient"
                  :z-index="1001"
              >

            
                <template v-slot:content>
                  <div class="input-firm">
                    <label class="label">Extension Assumption Firm系数%</label>
                    <a-input v-model:value="formState.firm" :placeholder="'请输入0-100之间的数字'" :required="true" :defaultValue="100" />
                  </div>
                  <div class="input-proposal">
                    <label class="label">Extension Assumption Proposal系数%</label>
                    <a-input v-model:value="formState.proposal" :placeholder="'请输入0-100之间的数字'" :required="true" :defaultValue="80"/>
                  </div>
                </template>
                </ModalBox>
              <Tooltip title="导出">
                <a-button class="left ml12" type="primary" ghost @click="exportClick" v-auth="['system:FCSTReport:export']">
                  <ExportOutlined />
                </a-button>
              </Tooltip>
            </div>
          </template>
        </FilterLayout>
      </div>
      <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="false" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
    </div>
    <FCSTList ref="FCSTListRef" @setting="settingHandler"/>
    <ColumnsEntry v-model:visible="columnsVisible" :checkList="checkColumnsList" @closeModal="closeColumnsModal" @resetList="checkListInit" />
  </section>
</template>

<script>
import Tooltip from "@/components/Tooltip";
import {ExportOutlined} from "@ant-design/icons-vue";
import MoreFormList from "@/components/MoreFormList";
import FormModalBox from "@/components/FormModalBox";
import {useGlobalPropertyHook} from "@/hooks/common";
import {onActivated, onMounted, reactive, ref, watch} from "vue";
import {dictionaryOpt, dictionaryOptions, dictionaryOptionsCode, handleJtpFilter} from "@/utils/common";
import {cloneDeep, debounce} from "lodash";
import FCSTList from "@/views/home/ARVE/FCSTReport/components/FCSTList";
import moment from "moment";
import {getValueAndOps} from "@/utils/orgSelectData";
import ColumnsEntry from '@/views/home/ARVE/FCSTReport/components/ColumnsEntry'
import { message } from "ant-design-vue";
import ModalBox from '@/components/ModalBox.vue';
export default {
  name: "index",
  components: {FCSTList, Tooltip, ExportOutlined,MoreFormList,FormModalBox,ColumnsEntry,ModalBox},
  setup() {

    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const FCSTListRef = ref();
    const orgFiveOneOps = ref([]);
    const staffGradeOps = ref([]);
    const roleOps = ref([]);
    const captiveOps = ref(dictionaryOpt(['Captive','Non Captive']));
    const jtpOps = ref([
      { label: "With JTP", value: "1" },
      { label: "Without JTP", value: "0" },
    ])
    let withJtpGradeOps;
    let withoutJtpGradeOps;
    const mode = ref(["month", "month"]);
    const orgOpsObj = reactive({
      orgThreeTwoOps: [],
      orgFourTwoOps: [],
      orgTwoOps: []
    });
    let initData = {
      orgThreeTwo: [],
      orgFourTwo: [],
      orgFiveOne: [],
      statisticsTime: [moment().format('YYYY-MM'),moment().add(3,'months').format('YYYY-MM')],
      beginDate: moment().format('YYYY-MM'),
      endDate: moment().add(3,'months').format('YYYY-MM'),
      condition: '',
      localGrade: [],
      role: [],
      captive: [],
      jtp: '0',
      orgTwo: [],
      firm:"",
      proposal:""
    }
    const formState = reactive({...initData});
    let currentState = {};
    const updateParams = () => {
      currentState = cloneDeep(formState);
    };
    const doSearch = () => {
      updateParams();
      if(currentState.beginDate&&currentState.endDate) {
        getQuickEntry('1')
      } else {
        FCSTListRef.value.searchClick(currentState);
      }
    };
    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      getOrgList()
      doSearch()
    };

    //筛选项
    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'arveManagement',
        secondMenu: 'fcstReport',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 4?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }

    //下拉项
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwo':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourTwo':
          return orgOpsObj.orgFourTwoOps
        case 'orgFiveOne':
          return orgFiveOneOps.value
        case 'localGrade':
          return staffGradeOps.value
        case 'role':
          return roleOps.value
        case 'captive':
          return captiveOps.value
        case 'jtp':
          return jtpOps.value
        case 'orgTwo':
          return orgOpsObj.orgTwoOps
        default :
          ''
      }
    }
    const getDictionary = () => {
      $api.getDictionaryList({params: {dictType: "role,grade",},}).then((res) => {
        roleOps.value = dictionaryOptions(res.data.role);
        withJtpGradeOps = cloneDeep(dictionaryOptionsCode(res.data.grade))
        withoutJtpGradeOps = handleJtpFilter(withJtpGradeOps)
        if (withoutJtpGradeOps && withJtpGradeOps) {
          staffGradeOps.value = formState.jtp === '0' ? withoutJtpGradeOps : withJtpGradeOps
        }
      });
    }
    watch(
        () => formState.jtp,
        (val) => {
          if (withoutJtpGradeOps && withJtpGradeOps) staffGradeOps.value = val === '0' ? withoutJtpGradeOps : withJtpGradeOps
          if (val === '0' && formState.localGrade.length) {
            formState.localGrade = handleJtpFilter(formState.localGrade)
          }
        },
        { immediate: true, deep: true }
    );

    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        orgFiveOneOps.value = dictionaryOpt(res.data.orgFiveOneList)
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(res.data.orgThreeTwoList)
        orgOpsObj.orgFourTwoOps = dictionaryOpt(res.data.orgFourTwo)
        orgOpsObj.orgTwoOps = dictionaryOpt(res.data.orgTwoList)
      });
    };
    const orgAllList = ref([]);
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data;
      });
    };

    //联动逻辑
    const getSelectChange = debounce((type)=> {
      switch (type) {
        case 'orgThreeTwo':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourTwo':
          return orgThreeTwoChange('orgFourTwo')
        case 'orgTwo':
          return orgThreeTwoChange('orgTwo')
        default :
          ''
      }
    },500)
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFourTwoList: formState.orgFourTwo,
        orgThreeTwoList: formState.orgThreeTwo,
        orgTwoList: formState.orgTwo,
      };
      const { orgOpsList } = getValueAndOps(
          orgAllList.value,
          formOrgData,
          orgOpsObj,
          type
      );
      Object.assign(orgOpsObj, orgOpsList);
    };

   //时间组件逻辑
    const isOpen = ref(false);
    const disabledBeginDate = (current) => {
      return (
          current &&
          formState.endDate &&
          current >= formState.endDate.endOf("day")
      );
    };
    const disabledEndDate = (current) => {
      return (
          current &&
          formState.beginDate &&
          current <= formState.beginDate.startOf("day")
      );
    };
    const getOpenChange = (status)=> {
      if (status) {
        isOpen.value = true;
      } else {
        isOpen.value = false;
        statisticsTimeDisabled()
      }
    }
    const getPanelChange = (val)=> {
      // if (
      //     formState.statisticsTime[1] &&
      //     formState.statisticsTime[1]._d !== val[1]._d
      // ) {
      //   isOpen.value = false;
      // }
      formState.beginDate = val[0].format("YYYYMM");
      formState.endDate = val[1].format("YYYYMM");
      formState.statisticsTime = [
        val[0].format("YYYY-MM"),
        val[1].format("YYYY-MM"),
      ];
    }
    watch(
        ()=>formState.statisticsTime,
        (val)=>{
          if(val.length>0){
            formState.beginDate = val[0]
            formState.endDate = val[1]
          }else{
            formState.beginDate = ''
            formState.endDate = ''
          }
        }
    )
    const statisticsTimeDisabled = () => {
      if(moment(formState.endDate).diff(moment(formState.beginDate),'month') > 12) {
        message.error("统计时间不能超过12个月，请重新选择")
        formState.statisticsTime = initData.statisticsTime
      }
    }

    //自定义表头
    const settingHandler = () => {
      columnsVisible.value = true
    }
    const columnsVisible = ref(false);
    const closeColumnsModal = (flag) => {
      columnsVisible.value = false
      // flag为true则进行保存
      if (flag) {
        getQuickEntry('1')
      }
    }
    const checkListInit = () => {
      $api.queryFCSTSort(0).then((res) => {
        checkColumnsList.value = [].concat([...res.data.sort(sortBy('sort'))])
      })
    }
    const checkColumnsList = ref([])
    const getQuickEntry = async (flag) => {
      updateParams()
      await FCSTListRef.value.searchClick(currentState);
      await $api.queryFCSTSort(flag).then((res) => {
        checkColumnsList.value = [].concat([...res.data.sort(sortBy('sort'))])
        sessionStorage.setItem('FCSTDataMoreSortColumns', JSON.stringify(
            checkColumnsList.value
        ))
        FCSTListRef.value.getColumns();
      });
    };
    const sortBy = (field) => {
      //根据传过来的字段进行排序
      return (x, y) => {
        return x[field] - y[field]
      }
    }
    const showForm = ref(false);

    const getDictValue = (data,code) => {
      const dictItem = data.find(item => item.dictCode === code);
      return dictItem ? dictItem.dictValue : "";
    }

    const configFactor = () => {
    showForm.value = true;
      $api.queryCoefficient().then((res)=>{
        if (res.code === 200) {
          formState.firm = getDictValue(res.data, 'extensionAssumptionFirm');
          formState.proposal = getDictValue(res.data, 'extensionAssumptionProposal');
        }
      });
    };
    const saveCoefficient = () => {
      if (!formState.firm.trim() || !formState.proposal.trim()) {
        // 显示错误提示
        message.error("请输入0-100之间的数字");
        
        return; // 如果有错误，不继续执行保存操作
      }
      if (!isValidValue(formState.firm) || !isValidValue(formState.proposal)) {
        // 显示错误提示
        // 这里可以根据你的实际情况进行处理，比如显示一个提示框
        message.error("请输入0-100之间的数字");
        return; // 如果有错误，不继续执行保存操作
      }
      const params = {
        extensionAssumptionFirm: formState.firm,
        extensionAssumptionProposal: formState.proposal
      }
      $api.saveCoefficient(params).then(() => {
        message.success('保存系数成功');
      showForm.value = false;}).catch((error)=>{
        message.error(error.msg);
      })

    }
    const cancelCoefficient = () =>{
      showForm.value = false;
    }
        // 验证输入值是否在0到100之间的函数
    const isValidValue = (value) => {
      return !isNaN(value) && Number(value) >= 0 && Number(value) <= 100;
    };
    //导出
    const exportClick = () => {
      const params = {
        ...currentState,
        type: '0',
      };
      $api.fcstReportExport(params).then((res) => {
        message.success(res.msg)
      });
    };

    const getChartContainerStyle = () => {
      FCSTListRef.value.getSize();
    };

    onMounted(() => {
      // nextTick(doSearch);
      getOrgList();
      getDictionary();
      getOrgRelatedList();
      getChartContainerStyle();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
      getQuickEntry('1');
    });

    onActivated(() => {
      FCSTListRef.value.doSearch(currentState);
    });

    return {
      formState,
      formRef,
      mode,
      isOpen,
      disabledBeginDate,
      disabledEndDate,
      doSearch,
      resetForm,
      exportClick,
      orgOpsObj,
      orgFiveOneOps,
      staffGradeOps,
      roleOps,
      captiveOps,
      jtpOps,
      orgThreeTwoChange,
      updateParams,
      getOrgRelatedList,
      getChartContainerStyle,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      getOpenChange,
      getPanelChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder,
      getDictionary,
      getOrgList,
      FCSTListRef,
      settingHandler,
      columnsVisible,
      closeColumnsModal,
      checkListInit,
      checkColumnsList,
      getQuickEntry,
      statisticsTimeDisabled,
      showForm,
      configFactor,
      saveCoefficient,
      cancelCoefficient
    };
  },
}
</script>
<style>
.ant-message {
  z-index: 10101 !important;
}
</style>
<style scoped lang="less">
.top-filter {
  .btnRadio {
    width: 100%;
    display: flex;
    .ant-radio-button-wrapper {
      width: 50%;
      text-align: center;
    }
  }


}
.input-firm{
  margin-top: 10px;
  width: 400px;
  height: 62px;
  // background-color: red;

}
.input-firm .label::after{
  content: "*";
  color: red;
}
.input-proposal{
  margin-top: 10px;
  width: 400px;
  height: 62px;
  // background-color: green;
}
.input-proposal .label::after{
  content: "*";
  color: red;
}
</style>
