<template>
  <section class="org-list">
    <a-table ref="FCSTListRef" :columns="columns" :data-source="FCSTList"
             :scroll="{y: tableHeight,x: 1400,scrollToFirstRowOnChange: true,}" :pagination="pagination">
      <template #operationHead>
        <Tooltip :title="'设置表头'">
          <SettingOutlined style="position:relative;left:5px;font-weight: 700;" class="icon-setting" @click="settingOutHandler"/>
        </Tooltip>
      </template>
    </a-table>
  </section>
</template>

<script>
import {defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import {useTableHeight} from "@/utils/common";
import {SettingOutlined} from "@ant-design/icons-vue";
import Tooltip from "@/components/Tooltip";
import {useGlobalPropertyHook} from "@/hooks/common";

export default defineComponent({
  name: "FCSTList",
  components: {
    SettingOutlined,
    Tooltip
  },
  emits: ["setting"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const FCSTList = ref([]);
    // 列表占位
    let columns = ref([
      {
        title: "columns",
        dataIndex: "",
        key: "",
        ellipsis: true,
        width: 150,
      },

    ])
    const firstHeader = () => {
      return {
        style: {
          "border-bottom": '5px solid rgb(255, 255, 255)',
          "border-left": '5px solid rgb(255, 255, 255)',
        }
      };
    }
    const leftHeader = () => {
      return {
        style: {
          "border-left": '5px solid rgb(255, 255, 255)',
        }
      };
    }
    let originColumns = [
      {
        title: "Senior/Junior",
        dataIndex: "seniorJunior",
        key: "seniorJunior",
        ellipsis: true,
        width: 200,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Employee Organization",
        dataIndex: "employeeOrganization",
        key: "employeeOrganization",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Global Group ID",
        dataIndex: "globalGroupId",
        key: "globalGroupId",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Resource CC",
        dataIndex: "resourceCc",
        key: "resourceCc",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Employee No",
        dataIndex: "employeeNo",
        key: "employeeNo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Local Grade",
        dataIndex: "localGrade",
        key: "localGrade",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Onboard Date",
        dataIndex: "onboardDate",
        key: "onboardDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Termination Date",
        dataIndex: "terminationDate",
        key: "terminationDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: 'Firm Booking',
        key: "firmBooking",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Extension Booking',
        key: "extensionBooking",
        children: [],
        customHeaderCell: firstHeader,
      },

      {
        title: 'Proposal Booking',
        key: "proposalBooking",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Paid Vacation',
        key: "paidVacation",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'CSS FTE',
        key: "cssFte",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'BD',
        key: "bd",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'COE',
        key: "coe",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Extension COE',
        key: "extensionCoe",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: "Hiring Type",
        dataIndex: "hiringType",
        key: "hiringType",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Booster Released Date",
        dataIndex: "boosterReleasedDate",
        key: "boosterReleasedDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Senior Role",
        dataIndex: "seniorRole",
        key: "seniorRole",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        ellipsis: true,
        width: 200,
      },
      {
        title: "ARVE Target",
        dataIndex: "arveTarget",
        key: "arveTarget",
        ellipsis: true,
        width: 200,
      },
      {
        title: "TAG",
        dataIndex: "tag",
        key: "tag",
        ellipsis: true,
        width: 200,
      },
      {
        title: "English",
        dataIndex: "english",
        key: "english",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Skill Set",
        dataIndex: "skillSet",
        key: "skillSet",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Available Date",
        dataIndex: "availabilityStartDate",
        key: "availabilityStartDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        ellipsis: true,
        width: 200,
      },
      {
        title: 'Money ARVE FCST',
        key: "moneyArveFcst",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Extension Money ARVE FCST',
        key: "extensionMoneyArveFcst",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Proposal Money ARVE FCST',
        key: "proposalMoneyArveFcst",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Operation ARVE FCST',
        key: "operationArveFcst",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Extension Operation ARVE FCST',
        key: "extensionOperationArveFcst",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Proposal Operation ARVE FCST',
        key: "proposalOperationArveFcst",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Actual',
        key: "actual",
        children: [
          {
            title: "Actual ARVE",
            dataIndex: "actualARVE",
            key: "actualARVE",
            ellipsis: true,
            width: 200,
            customHeaderCell: leftHeader,
          },
          {
            title: "GAP with FCST",
            dataIndex: "gapWithFcst",
            key: "gapWithFcst",
            ellipsis: true,
            width: 200,
          },
          {
            title: "GAP Reason",
            dataIndex: "gapReason",
            key: "gapReason",
            ellipsis: true,
            width: 200,
          },
          {
            title: "MTD BD%",
            dataIndex: "mtdBd",
            key: "mtdBd",
            ellipsis: true,
            width: 200,
          },
          {
            title: "MTD COE%",
            dataIndex: "mtdCoe",
            key: "mtdCoe",
            ellipsis: true,
            width: 200,
          },
          {
            title: "Operation ARVE+BD%+COE%",
            dataIndex: "operationArveBdCoe",
            key: "operationArveBdCoe",
            ellipsis: true,
            width: 300,
          },
        ],
        customHeaderCell: firstHeader,
      },
      {
        title: "ADRC",
        dataIndex: "adrc",
        key: "adrc",
        ellipsis: true,
        width: 200,
      },
      {
        title: 'DC ',
        key: "dc",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Extension DC ',
        key: "extensionDc",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Proposal DC ',
        key: "proposalDc",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'SC',
        key: "sc",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'BD% ',
        key: "bdProportion",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'COE%',
        key: "coeProportion",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: 'Extension COE%',
        key: "extensionCoeProportion",
        children: [],
        customHeaderCell: firstHeader,
      },
      {
        title: "JTP",
        dataIndex: "jtp",
        key: "jtp",
        ellipsis: true,
        width: 200,
      },
      {
        title: "JTP Promotion",
        dataIndex: "jtpPromotion",
        key: "jtpPromotion",
        ellipsis: true,
        width: 200,
      },
      {
        title: "PU Code",
        dataIndex: "puCode",
        key: "puCode",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org1",
        dataIndex: "orgOne",
        key: "orgOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org2 ",
        dataIndex: "orgTwo",
        key: "orgTwo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org3.1 ",
        dataIndex: "orgThreeOne",
        key: "orgThreeOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org3.1.1",
        dataIndex: "orgThreeOneOne",
        key: "orgThreeOneOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org3.2",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org4.1",
        dataIndex: "orgFourOne",
        key: "orgFourOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org4.1.1",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5",
        dataIndex: "orgFive",
        key: "orgFive",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org6.1",
        dataIndex: "orgSixOne",
        key: "orgSixOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org6.2",
        dataIndex: "orgSixTwo",
        key: "orgSixTwo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "ADRC1.1",
        dataIndex: "adrcOneOne",
        key: "adrcOneOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Base Location",
        dataIndex: "baseLocation",
        key: "baseLocation",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account Details",
        dataIndex: "accountDetails",
        key: "accountDetails",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Bench Days",
        dataIndex: "benchDays",
        key: "benchDays",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Bench Time",
        dataIndex: "benchTime",
        key: "benchTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Captive",
        dataIndex: "captive",
        key: "captive",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Detail Department",
        dataIndex: "detailDepartment",
        key: "detailDepartment",
        ellipsis: true,
        width: 200,
      },
      {
        title: "YTD ARVE",
        dataIndex: "ytdArve",
        key: "ytdArve",
        ellipsis: true,
        width: 200,
      },
      {
        title: "YTD BD%",
        dataIndex: "ytdBd",
        key: "ytdBd",
        ellipsis: true,
        width: 200,
      },
      {
        title: "YTD COE%",
        dataIndex: "ytdCoe",
        key: "ytdCoe",
        ellipsis: true,
        width: 200,
      },
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '20', '30', '40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });

    let searchCondition = {};
    const doSearch = async (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        type: '1',
        ...searchParams,
      };
      await $api.getFCSTReportList(params).then((res) => {
        pagination.total = res.data.total;
        FCSTList.value = res.data.resource;
      });
    };
    // 过滤列
    const getColumns = () => {
      let columnAllList = JSON.parse(sessionStorage.getItem('FCSTDataMoreSortColumns'))
      let columnList = columnAllList.map((item) => { return item.firstMenuId })
      let allList = [
        ...sortByOrder(JSON.parse(JSON.stringify(originColumns)).filter((item) => { return columnList.includes(item.key) }), columnList),
        {
          dataIndex: 'operationHead',
          key: 'operationHead',

          width: 100,
          fixed: "right",
          slots: {
            title: 'operationHead',
          },
        },
      ]
      allList.forEach((item,index) => {
        if(item.children && item?.key!=='actual') {
          columnAllList.filter(jtem => {return jtem.firstMenuId === item.key})[0].childrenList?.forEach((jtem,jndex) => {
            item.children.push({
              title: jtem.secondMenuId,
              dataIndex: jtem.firstMenuId,
              key: jtem.firstMenuId,
              ellipsis: true,
              width: 200,
              customHeaderCell: jndex===0 ? leftHeader : null,
            })
          })
        }
        if(index>0 && !item.children && allList[index-1].children) {
          item.customHeaderCell = leftHeader
        }
        if(index>0 && item.children) {
          item.customHeaderCell = firstHeader
        }
        if(index>0 && item?.key==='actual') {
          item.children[0].customHeaderCell = leftHeader
        }
      })
      columns.value = allList
    }

    //arr1 要排序的数组，arr2 排序的准则顺序
    const sortByOrder = (arr1, arr2) => {
      return arr1.sort((a, b) => {
        const indexA = arr2.indexOf(a.key);
        const indexB = arr2.indexOf(b.key);
        return indexA - indexB;
      });
    }

    const searchClick = async (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      await doSearch(searchCondition);
    };

    const settingOutHandler = () => {
      ctx.emit("setting");
    }

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      FCSTList,
      columns,
      pagination,
      doSearch,
      sortByOrder,
      searchClick,
      settingOutHandler,
      getSize,
      tableHeight,
      originColumns,
      getColumns,
    }
  }
})
</script>

<style scoped lang="less">

</style>
